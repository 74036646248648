<template>
    <section class="create-residence">
        <div class="return_page">
            <a @click="goBack">
                <i class="fas fa-arrow-left"></i>
                <span class="mobile">
                    <span v-if="isEditMode">
                        {{ $t("navigation.residenceBack") }}
                    </span>
                    <span v-else>
                        {{ $t("residencesList.form.return") }}
                    </span>
                </span>
            </a>
        </div>
        <div class="block-list">
            <h1 class="title">
                <span v-if="isEditMode && residenceChoice && !loadung">
                    {{ returnTitleEdit(residenceChoice) }}
                </span>
                <span v-else-if="!isEditMode && !loading">
                    {{ $t("residencesList.create") }}
                </span>
            </h1>
            <div class="middle-block" v-if="displayForm">
                <div class="residence-type" v-if="!isEditMode">
                    <div class="choice-and-empty">
                        <div class="empty" />
                        <div class="choice-type">
                            <div
                                class="icons"
                                @click="
                                    residenceChoice =
                                        residenceType.COMPLEX.toString()
                                "
                            >
                                <img
                                    class="type-icon"
                                    :src="
                                        require('@/assets/images/buildings/complex-residence-small.png')
                                    "
                                    alt="complex residence"
                                    width="60"
                                />
                                <div>
                                    <input
                                        type="radio"
                                        name="username"
                                        v-model="residenceChoice"
                                        :value="residenceType.COMPLEX"
                                    />
                                </div>
                            </div>
                            <div
                                class="icons"
                                @click="
                                    residenceChoice =
                                        residenceType.SIMPLIFIED.toString()
                                "
                            >
                                <img
                                    class="type-icon"
                                    :src="
                                        require('@/assets/images/buildings/simplified-residence-small.png')
                                    "
                                    alt="simplified residence"
                                    width="60"
                                />
                                <input
                                    type="radio"
                                    name="username"
                                    v-model="residenceChoice"
                                    :value="residenceType.SIMPLIFIED"
                                />
                            </div>
                            <div
                                class="icons"
                                @click="
                                    residenceChoice =
                                        residenceType.BASIC.toString()
                                "
                            >
                                <img
                                    class="type-icon"
                                    :src="
                                        require('@/assets/images/buildings/basic-residence-small.png')
                                    "
                                    alt="basic residence"
                                    width="60"
                                />
                                <input
                                    type="radio"
                                    name="username"
                                    v-model="residenceChoice"
                                    :value="residenceType.BASIC"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="choice-text">
                        <div class="resume" v-if="residenceChoice !== ''">
                            <div>
                                <h3>
                                    {{
                                        $t(
                                            `residencesList.form.types.title-${residenceChoice}`
                                        )
                                    }}
                                </h3>
                            </div>
                            <div>
                                <p>
                                    {{
                                        $t(
                                            `residencesList.form.types.detail-${residenceChoice}`
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="residence-form">
                    <form class="manual" @submit="createResidence">
                        <div class="row">
                            <div class="label">
                                {{ $t("residencesList.form.residence-name") }} *
                            </div>
                            <div class="value">
                                <basic-input
                                    name="name"
                                    v-model="name"
                                    :rules="isNotEmpty"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="label">
                                {{ $t("residencesList.form.country") }} *
                            </div>
                            <div class="value">
                                <basic-select
                                    name="country"
                                    @update:modelValue="updateCursor"
                                    v-model="country"
                                    placeholder="--"
                                    :options="countries"
                                    :required="true"
                                    :rules="isNotNull"
                                >
                                </basic-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="label">
                                {{ $t("residencesList.form.address") }} *
                            </div>
                            <div class="value">
                                <basic-input
                                    name="address"
                                    v-on:focusout="updateCursor"
                                    v-model="address"
                                    :rules="isNotEmpty"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="label">
                                {{ $t("residencesList.form.postal") }} /
                                {{ $t("residencesList.form.city") }} *
                            </div>
                            <div class="value half">
                                <div class="half">
                                    <basic-input
                                        class="postal-code"
                                        name="postalCode"
                                        v-on:focusout="updateCursor"
                                        v-model="zipcode"
                                        :rules="isNumberAndNotEmpty"
                                    />
                                    <basic-input
                                        class="city"
                                        name="city"
                                        v-on:focusout="updateCursor"
                                        v-model="city"
                                        :rules="isNotEmpty"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="isFrenchCode(country)">
                            <div class="label">
                                {{ $t("residencesList.form.vigik") }} *
                            </div>
                            <div class="value vigik-select">
                                <basic-select
                                    name="vigik"
                                    placeholder="--"
                                    :options="areas"
                                    v-model="areaCode"
                                    @update:modelValue="updateArea"
                                    :required="true"
                                    :displayValue="true"
                                    :rules="isNotNull"
                                />
                                <div
                                    v-show="vigikDepartmentAlert > 1"
                                    class="alert-wrapper"
                                >
                                    <p>
                                        {{
                                            $t(
                                                "residencesList.form.vigik-alert"
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="label">
                                {{ $t("residencesList.form.comment") }}
                            </div>
                            <div class="value">
                                <basic-input
                                    name="comment"
                                    type="textarea"
                                    :textarea="true"
                                    v-model="comment"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="label">
                                {{ $t("residencesList.form.agence") }}
                            </div>
                            <div class="value">
                                <basic-select
                                    name="agence"
                                    placeholder="--"
                                    :options="agences"
                                    v-model="agence"
                                    @update:modelValue="updateAgence"
                                    @more="getMoreAgence"
                                    :limit="agenceLimit"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="isIPass && isEditMode">
                            <div class="label">
                                {{
                                    $t(
                                        "residencesList.form.autorisation.default"
                                    )
                                }}
                            </div>
                            <div class="value">
                                <basic-select
                                    v-model="idautorisation"
                                    name="idautorisation"
                                    :options="autorisations"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="isIPass && isEditMode">
                            <div class="label">
                                {{
                                    $t(
                                        "residencesList.form.autorisation.command"
                                    )
                                }}
                            </div>
                            <div class="value">
                                <basic-select
                                    v-model="idautorisation_hf"
                                    name="idautorisation_hf"
                                    :options="autorisations"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="isIPass && isEditMode">
                            <div class="label">
                                {{
                                    $t("residencesList.form.autorisation.mobil")
                                }}
                            </div>
                            <div class="value">
                                <basic-select
                                    v-model="idautorisation_clemobile"
                                    name="idautorisation_clemobile"
                                    :options="autorisations"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="isIPass && isEditMode">
                            <div class="label">
                                {{
                                    $t("residencesList.form.autorisation.ipass")
                                }}
                            </div>
                            <div class="value select-choice">
                                {{ $t("text.yes") }}
                                <input
                                    type="radio"
                                    name="robot"
                                    v-model="robot"
                                    value="1"
                                />
                                {{ $t("text.no") }}
                                <input
                                    type="radio"
                                    name="robot"
                                    v-model="robot"
                                    value="0"
                                />
                            </div>
                        </div>
                        <div class="more-form" v-if="isEditMode">
                            <table class="table">
                                <thead
                                    @click="
                                        displayMoreFilter = !displayMoreFilter
                                    "
                                >
                                    <tr>
                                        <th colspan="3" class="center">
                                            {{
                                                $t(
                                                    "residencesList.form.advancedOptions"
                                                )
                                            }}
                                            <i
                                                v-if="displayMoreFilter"
                                                class="fas fa-chevron-up"
                                            ></i>
                                            <i
                                                v-else
                                                class="fas fa-chevron-down"
                                            ></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="displayMoreFilter">
                                    <tr
                                        v-if="
                                            parseFloat(residenceChoice) ===
                                                residenceType.COMPLEX ||
                                            parseFloat(residenceChoice) ===
                                                residenceType.SIMPLIFIED
                                        "
                                    >
                                        <td>
                                            {{
                                                $t(
                                                    "residencesList.form.locative"
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{ $t("text.yes") }}
                                            <input
                                                type="radio"
                                                name="locative"
                                                v-model="locativeChoice"
                                                :value="true"
                                            />
                                            {{ $t("text.no") }}
                                            <input
                                                type="radio"
                                                name="locative"
                                                v-model="locativeChoice"
                                                :value="false"
                                            />
                                        </td>
                                        <td>
                                            <basic-link
                                                v-if="locativeChoice"
                                                :text="
                                                    $t(
                                                        'residencesList.form.config'
                                                    )
                                                "
                                                @click="openLocativ"
                                            />
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="
                                            parseFloat(residenceChoice) ===
                                                residenceType.COMPLEX ||
                                            parseFloat(residenceChoice) ===
                                                residenceType.SIMPLIFIED
                                        "
                                    >
                                        <td>
                                            {{
                                                $t(
                                                    "residencesList.form.liberal"
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{ $t("text.yes") }}
                                            <input
                                                type="radio"
                                                name="liberal"
                                                v-model="liberalChoice"
                                                :value="true"
                                            />
                                            {{ $t("text.no") }}
                                            <input
                                                type="radio"
                                                name="liberal"
                                                v-model="liberalChoice"
                                                :value="false"
                                            />
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("residencesList.form.rgpd") }}
                                        </td>
                                        <td></td>
                                        <td>
                                            <basic-link
                                                :text="
                                                    $t(
                                                        'residencesList.form.config'
                                                    )
                                                "
                                                @click="openRGPD"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{
                                                $t(
                                                    "residencesList.form.delete-residence"
                                                )
                                            }}
                                        </td>
                                        <td></td>
                                        <td>
                                            <basic-link
                                                :text="
                                                    $t(
                                                        'residencesList.form.del'
                                                    )
                                                "
                                                color="#C33C54"
                                                @click="deleteVerif"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{
                                                $t(
                                                    "residencesList.form.residenceType"
                                                )
                                            }}
                                        </td>
                                        <td colspan="2">
                                            {{
                                                $t(
                                                    `residencesList.form.types.title-${residenceType.COMPLEX}`
                                                )
                                            }}
                                            <input
                                                type="radio"
                                                name="residenceType"
                                                v-model="editResidenceChoice"
                                                :value="residenceType.COMPLEX"
                                            />
                                            {{
                                                $t(
                                                    `residencesList.form.types.title-${residenceType.SIMPLIFIED}`
                                                )
                                            }}
                                            <input
                                                type="radio"
                                                name="residenceType"
                                                v-model="editResidenceChoice"
                                                :value="
                                                    residenceType.SIMPLIFIED
                                                "
                                                :disabled="
                                                    parseInt(
                                                        residenceChoice
                                                    ) ===
                                                    parseInt(
                                                        residenceType.COMPLEX
                                                    )
                                                "
                                            />
                                            {{
                                                $t(
                                                    `residencesList.form.types.title-${residenceType.BASIC}`
                                                )
                                            }}
                                            <input
                                                type="radio"
                                                name="residenceType"
                                                v-model="editResidenceChoice"
                                                :value="residenceType.BASIC"
                                                :disabled="
                                                    parseInt(
                                                        residenceChoice
                                                    ) ===
                                                        parseInt(
                                                            residenceType.SIMPLIFIED
                                                        ) ||
                                                    parseInt(
                                                        residenceChoice
                                                    ) ===
                                                        parseInt(
                                                            residenceType.COMPLEX
                                                        )
                                                "
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <basic-button class="back-button" type="submit">
                            <span>
                                {{ $t("button.validate") }}
                            </span>
                        </basic-button>
                    </form>
                    <div class="map mobile">
                        <div v-if="isEditMode">
                            <img
                                v-if="
                                    parseInt(residenceChoice) ===
                                    parseInt(residenceType.COMPLEX)
                                "
                                class="type-icon"
                                :src="
                                    require('@/assets/images/buildings/complex-residence-medium.png')
                                "
                                alt="complex residence"
                                width="100"
                            />
                            <img
                                v-if="
                                    parseInt(residenceChoice) ===
                                    parseInt(residenceType.SIMPLIFIED)
                                "
                                class="type-icon"
                                :src="
                                    require('@/assets/images/buildings/simplified-residence-medium.png')
                                "
                                alt="simplified residence"
                                width="100"
                            />
                            <img
                                v-if="
                                    parseInt(residenceChoice) ===
                                    parseInt(residenceType.BASIC)
                                "
                                class="type-icon"
                                :src="
                                    require('@/assets/images/buildings/basic-residence-medium.png')
                                "
                                alt="basic residence"
                                width="100"
                            />
                        </div>
                        <BasicMap
                            v-if="displayMap"
                            :map-center="mapCenter"
                            :map-zoom="mapZoom"
                            :displayIcon="displayIcon"
                            :icon-url="getIconUrl"
                        />
                        <loader v-else />
                    </div>
                </div>
            </div>
            <div v-else class="loader">
                <loader />
            </div>
        </div>
    </section>
</template>

<script>
import { residenceType } from "@/enums"
import Loader from "@/components/basic/Loader"
import BasicMap from "@/components/basic/BasicMap"
import BasicInput from "@/components/basic/BasicInput"
import BasicSelect from "@/components/basic/BasicSelect"
import BasicButton from "@/components/basic/BasicButton.vue"
import { getAgences, getAreas } from "@/services/intratone/accesstype"
import { getMiscellaneous } from "@/services/intratone/miscellaneous"
import { getGeocodingSearch } from "@/services/intratone/geocoding"
import {
    createResidence,
    deleteResidence,
    getAuthorisation,
    getResidenceById,
    updateResidence,
} from "@/services/intratone/residence"
import { useToast } from "vue-toastification"
import { useForm } from "vee-validate"
import BasicLink from "@/components/basic/BasicLink.vue"
const toast = useToast()
import v1mixin from "@/mixins/v1.js"

export default {
    name: "create",
    components: {
        BasicLink,
        Loader,
        BasicMap,
        BasicSelect,
        BasicInput,
        BasicButton,
    },
    data() {
        return {
            name: "",
            country: "",
            address: "",
            zipcode: "",
            city: "",
            area: "",
            comment: "",
            agence: "",
            residenceChoice: "",
            editResidenceChoice: "",
            residenceType,
            areas: [],
            agences: [],
            mapCenter: { lat: 50.05, lon: 1.416667 },
            mapZoom: 4,
            displayMap: false,
            areaCountPage: 1,
            agenceCountPage: 1,
            areaPage: 1,
            agencePage: 1,
            areaLimit: 1200,
            agenceLimit: 20,
            areaCode: "0",
            agenceCode: "0",
            displayIcon: false,
            displayForm: true,
            displayMoreFilter: false,
            locativeChoice: false,
            liberalChoice: false,
            rgpd: null,
            autorisations: [],
            idautorisation: null,
            idautorisation_hf: null,
            idautorisation_clemobile: null,
            robot: 0,
            vigikDepartmentAlert: 0,
            findPosition: true,
        }
    },
    mixins: [v1mixin],
    watch: {
        country: {
            immediate: true,
            handler(value) {
                if (this.isFrenchCode(value)) {
                    if (this.areas.length === 0) {
                        this.getAreas()
                    }
                }
            },
        },
    },
    setup() {
        const { validate, errors } = useForm()

        return {
            validate,
            errors,
        }
    },
    async created() {
        try {
            const params = {
                entities: ["countries", "sectors", "languages"],
                lng: this.$i18n.locale,
            }
            let miscellaneousResponse = await getMiscellaneous(params)
            if (miscellaneousResponse.state === "error") {
                throw new Error()
            }

            this.$store.commit(
                "miscellaneous/setCountries",
                miscellaneousResponse.data.list.countries
            )
            this.$store.commit(
                "miscellaneous/setIndustryFields",
                miscellaneousResponse.data.list.sectors
            )
            this.$store.commit(
                "miscellaneous/setLanguages",
                miscellaneousResponse.data.list.languages
            )
        } catch (error) {
            console.log(error)
        }
    },
    computed: {
        generateFullAddress() {
            return [this.address, this.zipcode, this.city]
                .filter(Boolean)
                .join(", ")
        },
        countries() {
            return this.$store.getters["miscellaneous/getAllFormatedCountries"]
        },
        isEditMode() {
            return !!this.$route.params.id
        },
        isIPass() {
            return !!this.accountIpass
        },
        getIconUrl() {
            if (!this.findPosition) {
                return require("@/assets/images/icons/question-solid.svg")
            } else if (
                parseInt(this.residenceChoice) ===
                parseInt(residenceType.COMPLEX)
            ) {
                return require("@/assets/images/buildings/complex-residence-medium.png")
            } else if (
                parseInt(this.residenceChoice) ===
                parseInt(residenceType.SIMPLIFIED)
            ) {
                return require("@/assets/images/buildings/simplified-residence-medium.png")
            } else if (
                parseInt(this.residenceChoice) === parseInt(residenceType.BASIC)
            ) {
                return require("@/assets/images/buildings/basic-residence-medium.png")
            } else {
                return require("@/assets/images/others/pin-location.svg")
            }
        },

        accountIpass() {
            return this.$store.getters["account/getIpass"]
        },
    },
    async mounted() {
        this.displayForm = false
        this.displayMap = false

        if (this.isEditMode) {
            if (this.isIPass) {
                await getAuthorisation({ idres: this.$route.params.id }).then(
                    (response) => {
                        response.data.list.forEach((e) => {
                            this.autorisations.push({
                                name: e.nom,
                                value: e.id,
                            })
                        })
                    }
                )
            }
            await getResidenceById(this.$route.params.id).then(
                async (response) => {
                    if (response.error === 0) {
                        if (response.data.geoarea_code) {
                            await getAreas({
                                limit: this.areaLimit,
                            }).then((areaResponse) => {
                                if (areaResponse.error === 0) {
                                    this.areas = []
                                    areaResponse.data.list.forEach((e) => {
                                        this.areas.push({
                                            value: e.code,
                                            name: e.label,
                                        })

                                        if (
                                            e.code ===
                                            response.data.geoarea_code
                                        ) {
                                            this.area = e.label.toString()
                                            this.areaCode = e.code
                                        }
                                    })
                                }
                            })
                        }
                        if (response.data.agence_id) {
                            await getAgences({
                                page: 1,
                                limit: this.agenceLimit,
                            }).then(async (agenceResponse) => {
                                if (agenceResponse.error === 0) {
                                    //If there is more than 20 agences, need to call with the good limit
                                    if (agenceResponse.data._count > 20) {
                                        this.agenceLimit =
                                            agenceResponse.data._count
                                        await getAgences({
                                            page: 1,
                                            limit: this.agenceLimit,
                                        }).then((responseNoLimit) => {
                                            this.pushToAgenceList(
                                                responseNoLimit,
                                                response
                                            )
                                        })
                                    } else {
                                        this.pushToAgenceList(
                                            agenceResponse,
                                            response
                                        )
                                    }
                                }
                            })
                        } else {
                            await getAgences({
                                page: 1,
                                limit: this.agenceLimit,
                            }).then(async (agenceResponse) => {
                                if (agenceResponse.error === 0) {
                                    //If there is more than 20 agences, need to call with the good limit
                                    if (agenceResponse.data._count > 20) {
                                        this.agenceLimit =
                                            agenceResponse.data._count
                                        await getAgences({
                                            page: 1,
                                            limit: this.agenceLimit,
                                        }).then((responseNoLimit) => {
                                            this.pushToAgenceList(
                                                responseNoLimit,
                                                0
                                            )
                                        })
                                    } else {
                                        this.pushToAgenceList(agenceResponse, 0)
                                    }
                                }
                            })
                        }

                        this.robot = response.data.robot
                        this.idautorisation = response.data.idautorisation
                        this.idautorisation_hf = response.data.idautorisation_hf
                        this.idautorisation_clemobile =
                            response.data.idautorisation_clemobile
                        this.name = response.data.nom
                        this.address = response.data.adr
                        this.zipcode = response.data.cp
                        this.city = response.data.ville
                        this.country = response.data.pays_id
                        this.liberalChoice =
                            response.data.liberale === "1" ? true : false
                        this.locativeChoice =
                            response.data.loc === "1" ? true : false
                        this.residenceChoice = response.data.simple
                        this.editResidenceChoice = response.data.simple
                        if (response.data.latitude && response.data.longitude) {
                            this.mapCenter = {
                                lat: response.data.latitude,
                                lon: response.data.longitude,
                            }
                            this.mapZoom = 20
                            this.displayIcon = true
                        } else {
                            this.displayIcon = false
                        }
                        this.comment = response.data.comments
                        this.rgpd = response.data.cnil
                        this.displayForm = true
                        this.displayMap = true
                    }
                }
            )
        } else {
            await getAgences().then(async (response) => {
                //If there is more than 20 agences, need to call with the good limit
                if (response.data._count > 20) {
                    this.agenceLimit = response.data._count
                    await getAgences({
                        page: 1,
                        limit: this.agenceLimit,
                    }).then((response) => {
                        this.pushToAgenceList(response, 0)
                    })
                } else {
                    this.pushToAgenceList(response, 0)
                }
            })
            this.displayForm = true
            this.displayMap = true
        }
    },
    methods: {
        pushToAgenceList(response, agenceId) {
            this.agences = []
            response.data.list.forEach((e) => {
                this.agences.push({
                    value: e.id,
                    name: e.nom,
                })

                if (agenceId && e.id === agenceId.data.agence_id) {
                    this.agence = e.id
                    this.agenceCode = e.id
                }
            })
        },
        isFrenchCode(value) {
            switch (value) {
                case "1":
                case "12":
                case "13":
                case "14":
                case "16":
                case "17":
                case "18":
                case "38":
                case "42":
                case "43":
                case "44":
                case "45":
                    return true
                default:
                    return false
            }
        },
        isNumberAndNotEmpty(value) {
            if (!value) {
                return this.$t("input.mandatory")
            } else if (/^([a-zA-Z\d]+\s?)+$/.test(value) === false) {
                return this.$t("input.number")
            }
            return true
        },
        isNotEmpty(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.mandatory")
            }
            return true
        },

        isNotNull(value) {
            if (!value || (value === "0" && this.isFrenchCode(this.country))) {
                return this.$t("input.mandatory")
            }
            return true
        },
        async getAreas(e = "", fromGeo = false, page = 1) {
            const recherche = fromGeo
                ? e
                : typeof e === "object"
                ? e.target.value
                : e
            this.area = recherche
            this.areas = []
            await getAreas({
                limit: this.areaLimit,
            }).then((response) => {
                this.areas = []
                response.data.list.forEach((e) => {
                    this.areas.push({
                        value: e.code,
                        name: e.label,
                    })
                    if (
                        this.area &&
                        e.label.toLowerCase() === this.area.toLowerCase()
                    ) {
                        this.area = e.label
                        this.areaCode = e.code

                        this.vigikDepartmentAlert += 1
                    }
                })
                this.areaCountPage = response.data._pages
                this.areaPage = response.data._page
                this.areaLimit = response.data._limit
            })
        },
        getAgences(page = 1) {
            this.agences = []
            getAgences({
                page: page,
            }).then((response) => {
                response.data.list.forEach((e) => {
                    this.agences.push({
                        value: e.id,
                        name: e.nom,
                    })
                })
                this.agenceCountPage = response.data._pages
                this.agencePage = response.data._page
                this.agenceLimit = response.data._limit
            })
        },
        goBack() {
            window.history.back()
        },
        updateCursor() {
            if (this.country && this.address && this.zipcode && this.city) {
                this.updateMarkerPosition()
            }
        },
        async updateMarkerPosition() {
            const fullAddresse = this.generateFullAddress
            this.displayMap = false
            await getGeocodingSearch({
                q: fullAddresse,
                street: this.address,
                country: this.country,
                postalcode: this.zipcode,
            }).then(async (response) => {
                if (!response.error) {
                    if (response.data.list.length === 0) {
                        this.findPosition = false
                    } else {
                        this.findPosition = true
                        await this.getAreas(
                            response.data.list[0].department,
                            true
                        )
                        this.mapCenter = {
                            lat: response.data.list[0].lat,
                            lon: response.data.list[0].lon,
                        }
                    }
                    this.mapZoom = 20
                    this.displayMap = true
                    this.displayIcon = true
                } else {
                    this.mapCenter = {
                        lat: 50.05,
                        lon: 1.416667,
                    }
                    this.mapZoom = 4
                    this.displayMap = true
                    this.displayIcon = false
                }
            })
        },
        async createResidence(e) {
            e.preventDefault()
            if (this.isEditMode) {
                this.updateResidence()
            } else {
                try {
                    if (this.residenceChoice === "") {
                        throw new Error(this.$t("alerts.form.residence_type"))
                    }
                    let validationResponse = await this.validate()

                    if (!validationResponse.valid) {
                        throw new Error(this.$t("alerts.form.incorrect"))
                    }
                    if (this.country === "0" || !this.country) {
                        throw new Error(this.$t("alerts.form.country"))
                    }
                    if (
                        (this.areaCode === "0" || this.area === null) &&
                        this.country === "1"
                    ) {
                        throw new Error(this.$t("alerts.form.vigik"))
                    }

                    const data = {
                        nom: this.name,
                        adr: this.address,
                        cp: this.zipcode,
                        ville: this.city,
                        pays_id: this.country,
                        simple: this.residenceChoice,
                        liberale: 0,
                        lat: this.mapCenter.lat,
                        lon: this.mapCenter.lon,
                        geoarea_code: this.areaCode,
                        agence_id: this.agenceCode,
                        comments: this.comment,
                    }

                    createResidence(data).then((response) => {
                        if (response.error === 0) {
                            this.$router.push({
                                name: "Residence",
                                params: {
                                    id: response.data.id,
                                },
                            })
                        }
                    })
                } catch (error) {
                    console.log(error)
                    this.loadingRegister = false
                    toast.info(error.message, {
                        icon: "fas fa-exclamation-circle",
                    })
                }
            }
        },
        updateArea(payload) {
            this.areaCode = payload
        },
        updateAgence(payload) {
            this.agenceCode = payload
        },
        getMore() {
            this.getAreas(this.area, false, parseInt(this.areaPage) + 1)
        },
        getMoreAgence() {
            this.getAgences(this.agence, parseInt(this.agencePage) + 1)
        },
        openRGPD() {
            this.openModal("rgpd", {
                title: "rgpd",
                name: this.name,
                rgpdStatus: this.rgpd,
                simple: this.residenceChoice,
                after: (rgpd) => {
                    this.rgpd = rgpd
                },
            })
        },
        deleteVerif() {
            this.openModal("validation", {
                title: "valid.delete-residence",
                question: this.$t("residencesList.form.delete"),
                valid: () => {
                    this.deleteResidence()
                },
            })
        },
        deleteResidence() {
            deleteResidence(this.$route.params.id).finally(() => {
                this.$router.push({
                    name: "Residences",
                })
            })
        },
        async updateResidence() {
            try {
                if (this.residenceChoice === "") {
                    throw new Error(this.$t("alerts.form.residence_type"))
                }
                let validationResponse = await this.validate()
                if (!validationResponse.valid) {
                    throw new Error(this.$t("alerts.form.incorrect"))
                }
                if (this.country === "0" || !this.country) {
                    throw new Error(this.$t("alerts.form.country"))
                }
                if (
                    (this.areaCode === "0" || this.area === null) &&
                    this.country === "1"
                ) {
                    throw new Error(this.$t("alerts.form.vigik"))
                }
                updateResidence(this.$route.params.id, {
                    nom: this.name,
                    adr: this.address,
                    cp: this.zipcode,
                    ville: this.city,
                    pays_id: this.country,
                    simple: this.editResidenceChoice,
                    liberale: this.liberalChoice ? 1 : 0,
                    lat: this.mapCenter.lat,
                    lon: this.mapCenter.lon,
                    geoarea_code: this.areaCode,
                    agence_id: this.agenceCode,
                    comments: this.comment,
                    loc: this.locativeChoice ? 1 : 0,
                    idautorisation: this.idautorisation,
                    idautorisation_hf: this.idautorisation_hf,
                    idautorisation_clemobile: this.idautorisation_clemobile,
                    robot: this.robot,
                }).then((response) => {
                    if (response.error === 0) {
                        toast.success(
                            this.$t("global.status.updateResidence"),
                            {
                                icon: "fas fa-exclamation-circle",
                            }
                        )
                        this.$router.go(-1)
                    }
                })
            } catch (error) {
                console.log(error)
                toast.info(error.message, {
                    icon: "fas fa-exclamation-circle",
                })
            }
        },
        openLocativ() {
            this.openV1Modal("data/location/type=0&middle=1", "Act_Zone")
        },

        returnTitleEdit(choice) {
            switch (choice) {
                case "0":
                    return this.$t("global.entities.residenceType.complex")
                case "1":
                    return this.$t("global.entities.residenceType.simplified")
                case "2":
                    return this.$t("global.entities.residenceType.basic")
                default:
                    break
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";
@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}
.btn-style {
    padding: 20px;
}
.center {
    text-align: center;
    cursor: pointer;
}
.create-residence {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100%;
    overflow: auto;
    .return_page {
        padding: 0.5em 4em;

        @media all and (max-width: 480px) {
            padding: 0.5em 2em;
        }

        a {
            color: $blue-darker;
            cursor: pointer;
            text-decoration: none;
            transition: color 200ms;
            font-family: "Avenir Heavy";
            font-size: 12px;
            i {
                margin-right: 10px;
                transition: margin 200ms;
            }
        }
        a:hover {
            color: $orange-neutral;
            i {
                margin-right: 15px;
            }
        }
    }
    .block-list {
        width: 100%;
        height: 100%;
        padding: 0 4em;
        overflow: auto;

        @media all and (max-width: 480px) {
            padding: 0.5em 2em;
        }

        .title {
            font-family: $font_avenir_black;
            width: 100%;
            @media all and (min-width: 768px) {
                font-size: $veryBig;
            }
            @media all and (max-width: 768px) {
                font-size: $big;
            }
        }
        .middle-block {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            .residence-type {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                align-content: flex-start;
                height: 100%;
                width: 100%;
                @media all and (min-width: 768px) {
                    flex-direction: row;
                }
                @media all and (max-width: 768px) {
                    flex-direction: column;
                }
                div {
                    width: 50%;
                }
                .choice-and-empty {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                    @media all and (max-width: 768px) {
                        width: 100%;
                    }
                    .empty {
                        width: 30%;
                        height: 2px;
                        @media all and (min-width: 768px) {
                            display: none;
                        }
                        @media all and (max-width: 768px) {
                            width: 30%;
                            display: flex;
                        }
                    }
                    .choice-type {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        height: 100%;

                        @media all and (min-width: 768px) {
                            width: 70%;
                        }
                        @media all and (max-width: 768px) {
                            width: 100%;
                        }
                        .icons {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            cursor: pointer;
                            gap: 20px;
                            width: 150px;
                            height: 100%;
                            div {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                            }
                        }
                    }
                }
                .choice-text {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    @media all and (max-width: 768px) {
                        width: 100%;
                    }
                    @media all and (min-width: 768px) {
                        width: 50%;
                    }
                    .resume {
                        width: 70%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        padding: 10px;
                        color: $blue-neutral;
                        @media all and (max-width: 768px) {
                            font-size: $small;
                            width: 100%;
                        }
                        @media all and (min-width: 768px) {
                            width: 70%;
                        }
                        div {
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            &.title {
                                @media all and (max-width: 768px) {
                                    font-size: $normal;
                                }
                            }
                            @media all and (max-width: 768px) {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
            .residence-form {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                align-content: flex-start;
                margin-top: 20px;
                div {
                    width: 50%;
                }
                .manual {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: center;
                    gap: 20px;
                    .row {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        width: 100%;
                        height: 100%;
                        @media all and (min-width: 768px) {
                            flex-direction: row;
                        }
                        @media all and (max-width: 768px) {
                            flex-direction: column;
                        }
                        .label {
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            align-content: center;
                            @media all and (min-width: 768px) {
                                width: 30%;
                                justify-content: flex-end;
                            }
                            @media all and (max-width: 768px) {
                                width: 100%;
                                justify-content: flex-start;
                            }
                        }
                        .value {
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            align-content: center;
                            @media all and (min-width: 768px) {
                                width: 70%;
                            }
                            @media all and (max-width: 768px) {
                                width: 100%;
                            }
                            div {
                                width: 100%;
                            }
                            .half {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                align-content: center;
                                gap: 20px;

                                .postal-code {
                                    width: 30%;
                                }

                                .city {
                                    width: 70%;
                                }
                            }

                            &.vigik-select {
                                display: flex;
                                flex-direction: column;
                                gap: 0.5em;

                                .alert-wrapper {
                                    p {
                                        margin: 0;
                                        font-size: $small;
                                        color: $orange;
                                    }
                                }
                            }
                        }
                        .select-choice {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            gap: 20px;
                        }
                    }
                    .more-form {
                        width: 100%;
                    }
                }
                .map {
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    gap: 10px;
                    @media all and (min-width: 768px) {
                        display: flex;
                    }
                    @media all and (max-width: 768px) {
                        display: none;
                    }
                    div {
                        width: 70%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                    }
                }
            }
        }

        .loader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            overflow: hidden;
        }
    }
}
</style>
